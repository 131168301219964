<template>
    <b-button
        class=" btnSize "
        :class=" selected  ? 'active' : '' "
        v-on:click=" this.click "
    >
        {{ size }}
    </b-button>
</template>
<style lang="scss">

button.btnSize,
button.btnSize:not(:disabled):active,
button.btnSize:not(:disabled):focus,
button.btnSize:not(:disabled):visited,
body:not(.is-desktop) button.btnSize:not(:disabled):not(.active):hover
{
    border-radius: 50% !important;
    border-color: white !important;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    background: white !important;
    color: black !important;
    text-align: center;
    font-weight: bold;
    word-wrap: normal;
    box-shadow: 0 2px 4px #00000029 !important;

    .icon-prateleira-infinita
    {
        position: absolute;
        width: 22px;
        top: -3px;
        right: calc(50% - 33px);
    }

    label,
    label:hover
    {
        cursor: pointer;
        margin: 0;
    }
}

button.btnSize:not(:last-of-type)
{
    margin-right: 10px;
}

button.btnSize:disabled,
button.btnSize:disabled:active,
button.btnSize:disabled:hover,
button.btnSize:disabled:focus,
button.btnSize:disabled:visited
{
    border: none !important;
    background-color: var(--button-disabled-background) !important;
    color: var(--button-disabled-text) !important;
    cursor: default !important;

    label,
    label:hover
    {
        cursor: default !important;
    }
}

body.is-desktop button.btnSize:not(:disabled):hover,
button.btnSize:not(:disabled).active,
button.btnSize:not(:disabled).active:active,
button.btnSize:not(:disabled).active:focus,
button.btnSize:not(:disabled).active:visited
{
    border: none;
    background-color: var(--selected-background-color) !important;
    color: var(--selected-text-color) !important;
    text-decoration: none !important;
}

</style>
<script>
export default {
    name: 'btn-sizes',
    props: ['size'],
    data() {
        return {
            selected: false,
        }
    },
    methods: {
        click() {
            this.selected = !this.selected
            this.$emit('click')
        },
    },
}
</script>
