<template>
    <div id="resellerWhatsapp" class="cursor-pointer" v-on:click="sendWhatsapp()">
        <div id="whatsapp-logo"></div>
    </div>
</template>
<style lang="scss">

#resellerWhatsapp
{
    z-index: 10;
    width: 100px;

    label
    {
        text-align: center;
        border-radius: 10px;
        background-color: #4fcc5d;
        color: white;
        padding-top: 10px;
        padding-bottom: 20px;
        margin: 0;
        line-height: 15px;
        display: none;
    }

    #whatsapp-logo
    {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../assets/img/logo-whatsapp.png");
        background-size: 45px 45px;
        width: 100px;
        height: 45px;
    }
}

@media (max-width: 575.98px)
{
    #resellerWhatsapp
    {
        position: fixed;
        bottom: 0;
        right: -14px;

        #whatsapp-logo
        {
            position: relative;
            top: -14px;
        }
    }
}

@media (min-width: 575.99px)
{
    #resellerWhatsapp
    {
        width: 50px;
        float: left;
        margin-top: -10px;

        #whatsapp-logo
        {
            width: 50px;
        }
    }
}

</style>
<script>
import {Utils} from '../utils'

export default {
    name: 'reseller-whatsapp',
    props: ['reseller'],
    mixins: [Utils],
    methods: {
        sendWhatsapp() {
            this.sendWhatsappToReseller(this.reseller, 'Olá, visitei sua vitrine. Gostaria de maiores informações sobre')
        },
    },
}
</script>
