<template>
    <b-overlay :show=" loadingApp ">
        <b-container id="vitrine" class="p-0 height-full" fluid :class="{blur:modalPromotionalStatus}">
            <b-overlay :show=" !reseller.VitrineRevendedorID " rounded="sm">

                <!-- Promocional Dia dos Namorados -->
                <b-modal id="modal-promotional" v-model="modalPromotionalStatus" :content-class="{'is-desktop':isDesktop(), 'is-mobile':isMobile()}" hide-footer centered no-close-on-backdrop no-close-on-esc no-enforce-focus>
                    <div class="d-block text-center">
                        <p class="titulo">QUERO DE PRESENTE</p>
                        <img :src=" require( '../../assets/img/icon-presente-lampada.png' ) " alt="Logo Promocional" />
                        <p class="conteudo">Selecione o produto que você amou, clique no ícone e envie para aquela pessoa especial que te daria esse presente!</p>
                    </div>
                </b-modal>
                <!-- / Promocional Dia dos Namorados -->

                <Headerbar
                    :reseller=" reseller "
                    :filtros=" Filtros "
                    :filtred=" filtred "
                    :destaques=" catalogs "
                    :sidebarBagIsOpen=" openSidebarBag "
                    :bag=" bag "
                    v-on:changeSidebarMenuStatus=" changeSidebarMenuStatus "
                    v-on:reloadBag=" loadItemsBag "
                    v-on:setFilters=" applyFilter "
                    v-on:applyFilterPrice=" applyFilterPrice "
                    v-on:goToVitrine=" goToVitrine "
                />
                <ResellerWhatsapp v-if=" isMobile() " :reseller=" reseller "/>
                <!-- NAO MOBILE -> CONSULTOR E LISTA DE VITRINES -->
                <b-container v-if=" isDesktop() " id="bannerIdentificacao" class="fixed-top" :class="showOutrasVitrines ? 'show-outras-vitrines' :  ''" fluid>
                    <b-row class="m-0">
                        <b-col md="4" lg="4" xl="3" v-if="reseller.NomeRevendedora">
                            <p class="small m-0">Consultor(a)</p>
                            <p class="nome m-0">
                                <label class="float-left">{{ reseller.NomeRevendedora }}</label>
                                <ResellerWhatsapp v-if=" !isMobile() " :reseller=" reseller "/>
                            </p>
                        </b-col>
                        <b-col md="8" lg="8" xl="9" class="d-flex">
                            <b-list-group horizontal>
                                <b-list-group-item
                                    v-for=" destaque in this.catalogs "
                                    v-bind:key=" destaque.VitrineID "
                                    @click=" goToVitrine( `/vitrine/${ destaque.VitrineID }`, destaque.VitrineID == reseller.VitrineRevendedorID ) "
                                    class=" text-truncate cursor-pointer "
                                >
                                    {{ destaque.VitrineNome }}
                                </b-list-group-item>
                            </b-list-group>
                            <b-button v-if="this.catalogs.length > 0" id="btnVerOutrasVitrines" variant="transparent" @click=" toggleOutrasVitrines ">VER OUTRAS VITRINES</b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- Produtos -->
                <b-container id="catalog" class="p-0" fluid>

                    <!-- MOBILE -> Filtros -->
                    <b-row id="filters" v-if=" !isDesktop() ">
                        <b-col class="p-0">
                            <b-navbar class="d-flex justify-content-center no-padding" toggleable="true" type="dark" variant="light">
                                <div class="title">
                                    <label>Filtros</label>
                                    <b-navbar-toggle target="catalogs-collapse">
                                        <template v-slot:default="{ expanded }">
                                            <b-icon v-if="expanded" icon="chevron-up"></b-icon>
                                            <b-icon v-else icon="chevron-down"></b-icon>
                                        </template>
                                    </b-navbar-toggle>
                                </div>
                                <b-collapse id="catalogs-collapse" is-nav>
                                    <b-container id="filters-container">
                                        <b-row class="w-100">
                                            <b-col cols="12" class="p-0_">
                                                <b-form-group>
                                                    <b-form-radio-group stacked
                                                                        id="filtred-order_by"
                                                                        name="filtred-order_by"
                                                                        v-model="filtred.order_by"
                                                                        :options="filtred.order_by_options"
                                                                        @input=" getAllProductsByReseller( 1 ) "
                                                    ></b-form-radio-group>
                                                </b-form-group>
                                            </b-col>
                                            <!--                                            <b-col cols="6" class="p-0">-->
                                            <!--                                                <b-card id="filtred-mobile-preco" title="PREÇO">-->
                                            <!--                                                    <b-card-text>-->
                                            <!--                                                        <label>De</label>-->
                                            <!--                                                        <vue-slider-->
                                            <!--                                                            :tooltip="'always'"-->
                                            <!--                                                            :max=" 1000 "-->
                                            <!--                                                            :data=" filtred.precos_range "-->
                                            <!--                                                            v-model="filtred.precos_base"-->
                                            <!--                                                            @drag-end=" applyFilterPrice() "-->
                                            <!--                                                        />-->
                                            <!--                                                        <label>Até</label>-->
                                            <!--                                                    </b-card-text>-->
                                            <!--                                                </b-card>-->
                                            <!--                                            </b-col>-->
                                        </b-row>
                                    </b-container>
                                </b-collapse>
                            </b-navbar>
                        </b-col>
                    </b-row>

                    <!-- Banner -->
                    <b-row id="bannerArea">
                        <b-col class="p-0">
                            <Banners :reseller=" reseller " ref="headerbar-banners"/>
                        </b-col>
                    </b-row>

                    <!-- Filtrar por tamanho -->
                    <template v-if=" !isDesktop() ">
                        <template v-if="this.Filtros.Tamanhos.length > 0">
                            <b-row id="filtrarTamanho">
                                <b-col>
                                    <label>TAMANHO</label>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-container>
                                            <b-row>
                                                <b-col
                                                    cols="2"
                                                    v-for="tamanho in this.Filtros.Tamanhos"
                                                    v-bind:key="tamanho.Tamanho"
                                                >
                                                    <btnSize
                                                        v-on:click=" selectSize( tamanho.Tamanho ) "
                                                        :size=" tamanho.Tamanho "
                                                    />
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <div style=" height: 20px "></div>
                        </template>
                    </template>

                    <!-- Listar Produtos -->
                    <template v-if="this.catalog.loading">
                        <b-row id="listarProdutos-loading">
                            <b-col>
                                Carregando produtos...
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else>
                        <template v-if="this.catalog.items.length === 0">
                            <b-row id="listarProdutos-empty">
                                <b-col>
                                    Olá, <br/>
                                    não encontramos produtos nos filtros realizados, <br/>
                                    faça um novo filtro ou
                                    <router-link
                                        v-slot="{ route, isExactActive }"
                                        :to="`/vitrine/${ getIDVitrineTodosProdutos() }/`"
                                        style="text-decoration: underline; color: black" class="cursor-pointer"
                                    >
                                        <label @click=" goToVitrine( route, isExactActive ) ">clique aqui</label>
                                    </router-link>
                                    e encontre seu produto.
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row id="listarProdutos">
                                <b-col
                                    sm="12" md="6" lg="4" xl="4" xxl="3"
                                    v-for="product in this.catalog.items"
                                    :key="product.CdItemMaterial"
                                >
                                    <b-overlay :show=" product.showLoading ">
                                        <b-card tag="article" class="cursor-pointer" :class=" product.showEstoque ? 'estoque-open' : '' ">
                                            <router-link
                                                tag="div"
                                                :to="`/vitrine/${ reseller.VitrineRevendedorID }/${ product.CdItemMaterial }`"
                                            >
                                                <b-card-header>
                                                    <b-img :src=" product.FotoUrl | resizeImage " :alt=" product.CdItemMaterial " @error="replaceByDefault"></b-img>
                                                </b-card-header>
                                            </router-link>
                                            <b-card-body class="text-center p-0">
                                                <p class="card-title" @click=" viewProduct( product.CdItemMaterial ) ">{{ product.Nome }}</p>
                                                <!-- Preço Anterior -->
                                                <label class="w-100 no-margin preco_anterior" @click=" viewProduct( product.CdItemMaterial ) ">
                                                    <template v-if="product.PrecoCheio > 0 && product.PrecoCheio !== product.Preco">
                                                        {{ product.PrecoCheio | numberToBRL }}
                                                    </template>
                                                </label>
                                                <!-- Preço atual e açoes -->
                                                <div class="d-flex justify-content-between">

                                                    <!-- Caso tenha APENAS UMA numeracao filtrada... pode add ao carrinho -->
                                                    <template v-if=" getUnicoTamanhoFiltrado ">
                                                        <b-button class="btn-addProductToBag" @click=" addProductToBag( $event, product, getUnicoTamanhoFiltrado ) " size="sm" variant="transparent">
                                                            <b-img :src=" getIconBag('black') " fluid alt=" Comprar "></b-img>
                                                        </b-button>
                                                    </template>

                                                    <!-- Exibir estoque -->
                                                    <template v-else>
                                                        <b-button class="btn-viewProductEstoque" @click=" viewProductEstoque( $event, product, getUnicoTamanhoFiltrado ) " size="sm" variant="transparent">
                                                            <b-img :src=" getIconBag('black') " fluid alt=" Comprar "></b-img>
                                                        </b-button>
                                                    </template>

                                                    <!-- Preço e Estoque -->
                                                    <div class="w-100">
                                                        <!-- Estoque do Produto -->
                                                        <template v-if=" product.showEstoque ">
                                                            <b-overlay :show="product.showLoadingEstoque" style="margin-top: -29px">
                                                                <div class="estoque" :style="` width: ${getEstoqueArea}px `">
                                                                    <p>Escolha o seu tamanho</p>
                                                                    <div class="estoqueArea" :style="` width: ${getEstoqueArea - 60}px `">
                                                                        <carousel
                                                                            :navigationEnabled="true"
                                                                            :paginationEnabled="false"
                                                                            :perPage=" ( getEstoqueArea - 60 ) > 150 ? 3 : 2 "
                                                                        >
                                                                            <template v-for="size in product.estoqueNumeracoes">
                                                                                <slide
                                                                                    :key="size"
                                                                                    :hidden="
                                                                                        ( hasMixedSizes && !isMixedSizes(size) ) ||
                                                                                        ( !hasMixedSizes && isMixedSizes(size) )
                                                                                    "
                                                                                >
                                                                                    <b-button
                                                                                        class=" btnSize btn-addProductToBag "
                                                                                        :class=" isOnlyPrateleiraInfinita( product, size ) ? 'prateleira_infinita' : '' "
                                                                                        @click=" addProductToBag( $event, product, size ) "
                                                                                        :disabled=" disableByEstoque(product, size) "
                                                                                    >
                                                                                        <img :src=" require( '../../assets/img/icone-aviao.png' ) "
                                                                                             alt="Prateleira Infinita"
                                                                                             class="icon-prateleira-infinita"
                                                                                             v-if=" isOnlyPrateleiraInfinita( product, size ) "
                                                                                        />
                                                                                        {{ size }}
                                                                                    </b-button>
                                                                                </slide>
                                                                            </template>
                                                                        </carousel>
                                                                    </div>
                                                                </div>
                                                            </b-overlay>
                                                        </template>
                                                        <!-- Preço do Produto -->
                                                        <template v-else>
                                                            <template v-if="product.IsPrecoLoja">
                                                                <label class="w-100 no-margin preco_atual" @click=" viewProduct( product.CdItemMaterial ) ">{{ product.Preco | numberToBRL }}</label>
                                                            </template>
                                                            <template v-else>
                                                                <template v-if="product.precoConsultado">
                                                                    <label class="w-100 no-margin preco_atual" @click=" viewProduct( product.CdItemMaterial ) ">{{ product.Preco | numberToBRL }}</label>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="d-flex justify-content-center align-items-center h-100" @click=" viewProduct( product.CdItemMaterial ) ">
                                                                        Carregando Preço
                                                                    </div>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </div>

                                                    <!-- Compartilhar produto -->
                                                    <b-button class="btn-shareProduct" @click=" shareProduct( $event, product ) " size="sm" variant="transparent">
                                                        <b-img :src=" getIconShare() " fluid alt=" Compartilhar "></b-img>
                                                    </b-button>
                                                </div>
                                                <!-- Frase Promocional -->
                                                <label class="promotional-text">Quero de Presente</label>
                                            </b-card-body>
                                        </b-card>
                                    </b-overlay>
                                </b-col>
                            </b-row>
                            <template v-if=" isMobile() ">
                                <b-row id="paginacao-mobile" v-if=" catalog.totalRows > catalog.items.length ">
                                    <b-col class="text-center" v-b-visible=" loadNextPage ">Carregando ...</b-col>
                                </b-row>
                            </template>
                            <template v-else>
                                <template v-if=" showPagination ">
                                    <b-row id="paginacao" v-if="catalog.totalRows > 12">
                                        <b-col>
                                            <b-pagination
                                                align="center"
                                                size="lg"
                                                variant="dark"
                                                @change="paginationChange"
                                                v-model="catalog.currentPage"
                                                :total-rows="catalog.totalRows"
                                                :per-page="catalog.perPage"
                                            >
                                            </b-pagination>
                                        </b-col>
                                    </b-row>
                                </template>
                            </template>
                        </template>
                    </template>

                    <!-- Visualizar Produto -->
                    <b-row id="visualizarProduto">
                        <b-col>
                            <b-sidebar
                                id="sidebar-product"
                                right shadow
                                backdrop
                                backdrop-variant="transparent"
                                aria-label="Sidebar with product"
                                @hidden=" hideSidebarModalProduct "
                                :visible=" showSidebarProduct "
                                :no-close-on-route-change=" true "
                            >
                                <template v-slot:header-close="">
                                    <b-icon-x @click=" hideSidebarModalProduct "/>
                                </template>
                                <router-view name="product" :reseller="reseller" :product="product" :hasMixedSizes="hasMixedSizes" v-on:reloadBag=" loadItemsBag "></router-view>
                            </b-sidebar>
                            <b-modal
                                id="modal-product"
                                centered hide-footer
                                :no-close-on-backdrop=" true "
                                :no-enforce-focus=" true "
                                :no-close-on-esc=" true "
                                @hidden=" hideSidebarModalProduct "
                            >
                                <router-view name="product" :reseller="reseller" :product="product" :hasMixedSizes="hasMixedSizes" v-on:reloadBag=" loadItemsBag "></router-view>
                            </b-modal>
                        </b-col>
                    </b-row>

                </b-container>
            </b-overlay>
        </b-container>
    </b-overlay>
</template>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#vitrine.blur{
    filter: blur(4px) !important;
    overflow: hidden;
}
#modal-promotional{

    .modal-dialog{
        justify-content: center;

        .modal-content{

            &.is-desktop{max-width: 360px;}
            &.is-mobile{max-width: 350px;}

            border-color: #f25151 !important;
            background-color: #f25151 !important;
            color: white !important;
            border-radius: 25px !important;
            .modal-header{
                padding-top: 5px !important;
                position: absolute !important;
                right: 0;
                border: none !important;
                .close{
                    color: white !important;
                    opacity: 1 !important;
                    z-index: 999;
                }
            }
            .modal-body{
                img{
                    width: 64px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
                p{
                    font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
                    margin: 0;
                    &.titulo{
                        font-size: 14pt;
                    }
                    &.conteudo{
                        font-size: 11pt;
                    }
                }
            }
        }
    }
}

#vitrine
{
    // Aplicar Font MontSerra
    font-family: "Montserrat", sans-serif;
    background-color: #F1F1F1;

    #catalog
    {
        // Fix - Layout responsivo
        .row
        {
            margin: 0
        }

        //<!-- Filtros -->
        #filters
        {
            .navbar
            {
                .title
                {
                    color: var(--text-color);

                    label
                    {
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    svg
                    {
                        fill: var(--text-color);
                    }
                }

                label
                {
                    font-weight: bold;
                    margin: 0;
                }

                .navbar-toggler
                {
                    color: black;
                }

                #catalogs-collapse
                {
                    a
                    {
                        color: black;
                        font-weight: bold;
                    }

                    #filters-container
                    {
                        width: clamp(300px, 100%, 420px);
                        padding-top: 10px;
                        padding-bottom: 10px;

                        #filtred-order_by
                        {
                            label
                            {
                                font-size: 12px;
                                padding-top: 3px;
                            }
                        }

                        #filtred-mobile-preco
                        {
                            border-radius: 10px;

                            .card-body
                            {
                                text-align: center;
                                padding: 0;

                                .card-title
                                {
                                    font-size: 15px;
                                    border-top-left-radius: 10px;
                                    border-top-right-radius: 10px;
                                    background-color: black;
                                    color: white;
                                    padding: 5px 0;
                                }

                                .card-text
                                {
                                    display: flex;
                                    padding: 5px;

                                    label
                                    {
                                        font-size: 10px;
                                    }

                                    .vue-slider
                                    {
                                        width: 120px !important;
                                        padding: 7px 15px !important;

                                        .vue-slider-rail
                                        {
                                            .vue-slider-process
                                            {
                                                background-color: black;
                                            }

                                            .vue-slider-dot
                                            {
                                                .vue-slider-dot-handle
                                                {
                                                    border-color: black;
                                                }

                                                .vue-slider-dot-tooltip
                                                {
                                                    top: 0;

                                                    .vue-slider-dot-tooltip-inner
                                                    {
                                                        padding: 0;
                                                        background: transparent;
                                                        border-radius: unset;
                                                        border-color: transparent;
                                                        box-shadow: none;

                                                        .vue-slider-dot-tooltip-text
                                                        {
                                                            font-size: 10px;
                                                            color: black;
                                                            font-weight: bold;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //<!-- Filtrar por tamanho -->
        #filtrarTamanho
        {
            margin-top: 10px;
            margin-bottom: 10px;

            .row
            {
                align-items: center;
                justify-content: center;

                .col-2
                {
                    padding-left: 0;

                    button
                    {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        //<!-- Listar Produtos -->
        #listarProdutos
        {
            article.card
            {
                border-radius: 23px;
                background-color: white;
                margin-bottom: 34px;
                display: flex;
                align-items: center;
                border: none;
                margin-left: 10px;
                margin-right: 10px;

                .card-header
                {
                    padding: 0;
                    background-color: white;
                    border: none;
                    display: flex;
                    justify-content: center;

                    img
                    {
                        padding: 10% 15% 10%;
                        max-width: max-content;
                    }
                }

                .card-body
                {
                    width: 100%;

                    .card-title
                    {
                        font-size: 12px;
                        text-align: center;
                    }

                    img.img-fluid
                    {
                        width: 28px;
                    }

                    .btn-shareProduct{
                        img{
                            width: 32px;
                        }
                    }

                    .promotional-text{
                        position: absolute;
                        margin: -5px 0 0;
                        font-size: 8px;
                        right: 10px;
                    }

                    .estoque
                    {
                        height: 100%;
                        background-color: #F5F5F5;
                        border-radius: 15px;
                        box-shadow: 0 3px 6px #00000029;

                        p
                        {
                            font-size: 14px;
                            color: var(--text-color);
                            margin: 0;
                        }

                        .VueCarousel
                        {
                            margin-top: 5px;

                            .VueCarousel-wrapper
                            {
                                margin-left: 30px;
                                margin-bottom: 5px;
                                height: 55px;

                                .VueCarousel-inner
                                {
                                    button.prateleira_infinita
                                    {
                                        img
                                        {
                                            top: 0;
                                            right: inherit;
                                            margin-left: 17px;
                                        }

                                    }
                                }
                            }

                            .VueCarousel-navigation
                            {
                                .VueCarousel-navigation-prev
                                {
                                    margin: 0 0 0 30px;
                                }

                                .VueCarousel-navigation-next
                                {
                                    position: absolute;
                                    right: -30px;
                                }
                            }
                        }
                    }
                }
            }

            article.card.estoque-open
            {
                .justify-content-between
                {
                    .btn-viewProductEstoque
                    {
                        background-color: #F5F5F5;
                        border-radius: 15px 0 0 15px;
                    }
                }
            }
        }

        #paginacao,
        #paginacao-mobile
        {
            height: 50px;
            margin-bottom: 30px;

            .col
            {
                .b-pagination
                {
                    margin: 0;

                    li.disabled
                    {
                        display: none;
                    }

                    .page-link
                    {
                        border: 0;
                        background-color: transparent;
                        color: var(--pagination-text-color);
                        font-weight: bold;
                        box-shadow: none;
                    }

                    li.active
                    {
                        .page-link
                        {
                            background-color: var(--pagination-selected-background-color);
                            color: var(--pagination-selected-text-color);
                        }
                    }
                }
            }
        }
    }
}

.is-desktop
{
    #vitrine
    {
        background-color: white;

        #bannerIdentificacao
        {
            box-shadow: 0 12px 12px #00000029;
            z-index: 22;
            position: fixed;
            top: 60px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: white;
            height: 70px;

            p.small
            {
                font-size: 10px;
            }

            p.nome
            {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
            }

            @mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 5)
            {
                .list-group
                {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -$gutter;
                    margin-top: -$gutter;

                    .list-group-item
                    {
                        flex: 1 0 $min-width;
                        margin-left: $gutter;
                        margin-top: $gutter;

                        @if $last-equal
                        {
                            @for $i from 2 through $max-cols
                            {
                                $screen-width: ($min-width*$i)+($gutter*$i);
                                $column-width: (100%/$i);
                                @media (min-width: $screen-width)
                                {
                                    max-width: calc(#{$column-width} - #{$gutter});
                                }
                            }

                            $column-width: (100%/$max-cols);
                            @media (min-width: $min-width*$max-cols)
                            {
                                min-width: calc(#{$column-width} - #{$gutter});
                            }
                        }
                    }
                }
            }
            @include n-columns(350px, 10px, true, 4);

            .list-group
            {
                height: 60px;
                overflow: hidden;
                flex-basis: inherit;
                padding-top: 15px;

                .list-group-item
                {
                    border: none;
                    padding: 10px;
                }
            }

            #btnVerOutrasVitrines
            {
                width: 300px;
                height: 50px;
                font-weight: bold;
                padding-top: 25px;
            }
        }

        #bannerIdentificacao.show-outras-vitrines
        {
            height: auto;

            .list-group
            {
                height: auto;
                overflow: inherit;
                padding-top: 0;

                .list-group-item
                {
                    padding: 0 !important;
                }
            }
        }

        #bannerArea
        {
            background-color: white;
        }

        #catalog
        {
            margin-top: 80px
        }
    }
}

#modal-product *,
#vitrine *
{
    font-family: "Montserrat", sans-serif;
}

.preco_anterior
{
    color: #DE0000;
    font-size: 16px;
    text-decoration: line-through;
    font-weight: bold;
}

.preco_atual
{
    font-size: 26px;
    font-weight: bold;
}

.btnComprar,
.btnComprar:hover,
.btnComprar:focus,
.btnComprar:visited
{
    width: 150px;
    height: 60px;
    border-radius: 15px;
    background-color: var(--selected-background-color);
    color: var(--selected-text-color);
    border: none;
}

#sidebar-product
{
    width: 100%;
    height: 100% !important;
    margin-top: 60px !important;

    .b-sidebar-header
    {
        background-color: white;
    }

    header
    {
        button.close
        {
            opacity: 1;

            svg
            {
                color: black
            }
        }
    }

    #product-footer
    {
        background-color: white;
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: 200px;
        flex: 0 0 200px;

        p.nome_produto
        {
            font-size: 14px;
        }
    }
}

#modal-product
{
    .modal-dialog
    {
        width: 1000px !important;
        max-width: 1000px !important;

        .modal-content
        {
            .modal-body
            {
                height: 600px !important;
                max-height: 600px !important;
                padding: 0;
            }

        }
    }
}

#sidebar-product,
#modal-product
{
    &.destacar-numeracao
    {
        #tab-tamanhos
        {
            .btnSize:not(:disabled)
            {
                border: 1px solid red !important;
            }
        }

        .tooltip
        {
            display: none !important;
        }
    }
}

@media (min-width: 1440px)
{
    div[xxl="3"]
    {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

</style>
<script>
import Vue from 'vue'
import store from '@/store'
import {Utils} from '@/components/utils'
import Headerbar from '../../components/vitrine/headerbar'
import ResellerWhatsapp from '../../components/vitrine/resellerWhatsapp'
import Banners from '../../components/vitrine/banners'
import btnSize from '../../components/vitrine/btnSize'
import {Carousel, Slide} from 'vue-carousel'

// Slider do preço
// import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/antd.css'

export default {
    name: 'vitrine',
    props: ['VitrineResellerId'],
    components: {Headerbar, ResellerWhatsapp, Banners, Carousel, Slide, btnSize},
    // components: { Headerbar, ResellerWhatsapp, Banners, Carousel, Slide, btnSize, VueSlider },
    mixins: [Utils],
    data() {
        return {
            loadingApp: false,
            modalPromotionalStatus: false,
            // Revendedor
            reseller: {},
            // Filtros
            Filtros: {
                Categorias: [],
                Cores: [],
                Tamanhos: [],
                TamanhosBolsas: [],
                TamanhosSaltos: [],
                Material: [],
                Precos: [],
                OrderBy: null,
            },
            filtred: {
                categorias: [],
                cores: [],
                tamanhos: [],
                tamanhos_selecionados: [],
                tamanhos_bolsas: [],
                tamanhos_saltos: [],
                material: [],
                precos: [],
                precos_base: [0, 1000],
                precos_range: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
                pesquisa: '',
                order_by: null,
                order_by_fake: null,
                order_by_options: [
                    {text: 'Menor Preço', value: 'M'},
                    {text: 'Maior Preço', value: 'A'},
                    {text: 'Maior Desconto', value: 'D'},
                    {text: 'Últimos Lançamentos', value: null},
                ],
            },
            // Vitrine
            showOutrasVitrines: false,
            catalogs: [],
            catalogDisabled: false,
            showPagination: false,
            catalog: {
                error: null,
                loading: true,
                currentPage: null,
                totalRows: 0,
                perPage: 12,
                items: [],
            },
            // Produto
            product: {
                error: false,
                estoque: [],
                estoqueNumeracoes: [],
                LojasEstoqueAgrupado: [],
                Cnpj: null,
                FuncIdo: null,
                IdSistema: null,
                VendedorCpf: null,
                CodLoja: null,
                Referencia: null,
                Cor: null,
                Material: null,
                FotoPrincipal: null,
                FotoGaleria: null,
                FotoGaleriaZoom: null,
                TamanhoSalto: null,
                TodasFotos: [],
                OutrasCores: [],
                Nome: null,
                CdItemMaterial: null,
                Descricao: null,
                Preco: 0,
                PrecoCheio: 0,
                PrecoEcommerce: 0,
                IsPrecoLoja: true,
            },
            showSidebarProduct: false,
            showModalProduct: false,
            showLoadingEstoque: false,
            hasMixedSizes: false,
            // Bag
            openSidebarBag: false,
            bag: [],
        }
    },
    methods: {
        showPromotionalModal(){
            this.modalPromotionalStatus = true;
        },
        goToVitrine(router, isExactActive) {
            if (isExactActive) {
                // Limpar filtros
                this.clearMemory()

                // Carregar produtos
                this.getAllProductsByReseller(1)

                // Carregar filtros
                this.GetFiltrosByVitrine()

            }
            else {
                const route = typeof router === 'string' ? router : router.fullPath
                this.$router.push(route)
            }
        },
        // Revendedor
        clearMemory() {
            this.showOutrasVitrines = false
            // Filtros
            this.Filtros = {
                Categorias: [],
                Cores: [],
                Tamanhos: [],
                TamanhosBolsas: [],
                TamanhosSaltos: [],
                Material: [],
                Precos: [],
                OrderBy: null,
            }
            this.filtred = {
                categorias: [],
                cores: [],
                tamanhos: [],
                tamanhos_selecionados: [],
                tamanhos_bolsas: [],
                tamanhos_saltos: [],
                material: [],
                precos: [],
                pesquisa: '',
                precos_base: [0, 1000],
                precos_range: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
                order_by: null,
                order_by_fake: null,
                order_by_options: [
                    {text: 'Menor Preço', value: 'M'},
                    {text: 'Maior Preço', value: 'A'},
                    {text: 'Maior Desconto', value: 'D'},
                    {text: 'Últimos Lançamentos', value: null},
                ],
            }
        },
        GetInfo() {

            // Limpar catalogo
            this.catalogs = []
            this.reseller = {}

            // Zerar variaveis
            this.clearMemory()

            // this.catalog.items = [];
            store.dispatch('GetInfo', this.VitrineResellerId)
                .then(reseller => {

                    if (Vue.lodash.isEmpty(reseller)) {
                        this.$router.push({name: 'page-not-found'})
                    }
                    else {
                        // Identificar vendedor
                        this.reseller = reseller
                        window['IS_RESELLER'] = this.reseller.RevId !== null
                        this.reseller.RevId = window['IS_RESELLER'] ? this.reseller.RevId : ''

                        // Verificar se há um revendedor
                        if (Vue.lodash.isEmpty(this.reseller)) {
                            this.$router.push({name: 'page-not-found'})
                        }
                        else {
                            // Verificar se há um revendedor ativo
                            if (this.reseller.Ativo !== 'S') {
                                this.$router.push({name: 'page-not-found'})
                            }
                        }

                        // Identificar tipo de vitrine
                        window['IsRevendedora'] = this.reseller.IsRevendedora
                        window['IsVitrineRapida'] = this.reseller.IsVitrineRapida
                        window['IsVitrineInteligente'] = this.reseller.IsVitrineInteligente
                        window['CodLoja'] = this.reseller.CodLoja

                        // Carregar bag
                        this.loadItemsBag()

                        // Carregar produtos
                        this.getAllProductsByReseller(1)

                        // Carregar Outras Vitrines
                        this.getAllVitrinesByReseller()

                        // Carregar filtros
                        this.GetFiltrosByVitrine()

                        // Verificar se precisa carregar produto
                        if (Vue.lodash.isEmpty(this.$root.$route.params.CdIteMaterial) === false) {
                            this.loadingApp = true
                            this.viewProduct(this.$root.$route.params.CdIteMaterial)
                        }
                        else {
                            this.showPagination = true
                        }
                    }

                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        // Filtros
        applyFilter(type, value, adicionar) {

            if (type === 'pesquisa') {
                this.filtred[type] = value
            }
            else {
                if (adicionar) {
                    this.filtred[type].push(value)
                }
                else {
                    this.filtred[type] = Vue.lodash.without(this.filtred[type], value)
                }
            }

            // Carregar produtos
            this.getAllProductsByReseller(1)
        },
        selectSize(tamanho) {

            // Verificar se tamanho já foi selecionado
            const isSizeSelected = Vue.lodash.indexOf(this.filtred.tamanhos, tamanho) >= 0
            if (isSizeSelected) {
                this.filtred.tamanhos = Vue.lodash.without(this.filtred.tamanhos, tamanho)
            }
            else {
                this.filtred.tamanhos.push(tamanho)
            }

            // Atualizar lista
            this.getAllProductsByReseller(this.catalog.currentPage)
        },
        applyFilterPrice() {
            this.filtred.precos = this.filtred.precos_base;
            this.Filtros.Precos = this.filtred.precos_base;
            this.getAllProductsByReseller(1);
        },
        getIDVitrineTodosProdutos() {
            const VitrineTodosProdutos = Vue.lodash.first(this.catalogs)
            return Vue.lodash.isEmpty(VitrineTodosProdutos) ? this.VitrineResellerId : VitrineTodosProdutos.VitrineID
        },
        toggleOutrasVitrines() {
            this.showOutrasVitrines = !this.showOutrasVitrines
        },
        // Vitrine
        getAllProductsByReseller(Pagina, Acumular = false) {

            if (Acumular === false) {
                this.catalog.loading = true
                this.catalog.items = []
            }

            this.catalog.currentPage = Pagina

            // Carregar Produtos
            let payload = {
                VitrineResellerId: this.reseller.VitrineRevendedorID,
                TamPagina: this.catalog.perPage,
                Pagina: Pagina,
                // Filtros
                Categorias: this.filtred.categorias,
                Cores: this.filtred.cores,
                Tamanhos: this.filtred.tamanhos,
                TamanhosBolsas: this.filtred.tamanhos_bolsas,
                TamanhosSaltos: this.filtred.tamanhos_saltos,
                Material: this.filtred.material,
                Precos: this.filtred.precos,
                Pesquisa: this.filtred.pesquisa,
                OrderBy: this.filtred.order_by,
            }

            // FIX
            if (Vue.lodash.isEmpty(payload.Pesquisa) === false) {
                this.reseller.VitrineRevendedorID = this.getIDVitrineTodosProdutos()
                payload.VitrineResellerId = this.reseller.VitrineRevendedorID
            }

            store.dispatch('getAllProductsByReseller', payload)
                .then(response => {

                    if (Vue.lodash.isEmpty(response) === false) {

                        // Fix
                        Vue.lodash.each(response.Result, (produto) => {
                            produto.precoConsultado = produto.IsPrecoLoja
                            produto.estoque = []
                            produto.estoqueNumeracoes = []
                            produto.showEstoque = false
                            produto.showLoadingEstoque = false
                            produto.showLoading = false
                            produto.FotoGaleria = produto.FotoUrl
                            produto.FotoGaleriaZoom = produto.FotoUrl
                        })

                        // Identificar produtos
                        if (Acumular === true) {
                            Vue.lodash.each(response.Result, (produto) => {
                                this.catalog.items.push(produto)
                            })
                        }
                        else {
                            this.catalog.items = response.Result
                        }

                        this.getPrecos(response.Result)

                        this.catalog.totalRows = response.TotalItens
                        this.catalogDisabled = this.catalog.items.length === 0
                    }

                    this.catalog.loading = false
                })
                .catch((error) => {
                    // Caso ocorra um erro
                    this.catalog.loading = false
                    console.log('error', error)
                })
        },
        GetFiltrosByVitrine() {
            this.Filtros = {
                Categorias: [],
                Cores: [],
                Tamanhos: [],
                TamanhosBolsas: [],
                TamanhosSaltos: [],
                Material: [],
                Precos: [],
                OrderBy: null,
            }
            store.dispatch('GetFiltrosByVitrine', this.reseller.VitrineRevendedorID)
                .then(Filtros => {
                    if (Vue.lodash.isEmpty(Filtros) === false) {
                        this.Filtros = Filtros
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        loadNextPage(isVisible) {
            if (isVisible) {
                this.getAllProductsByReseller(this.catalog.currentPage + 1, true)
            }
        },
        paginationChange(page) {
            this.getAllProductsByReseller(page)
        },
        getPrecos(items) {

            const errorMessage = 'Ops, não estamos conseguindo obter os preços praticados em loja. <br>Confirme com sua vendedora o valor final.'
            const lista = []

            Vue.lodash.each(items, item => {
                if (item.precoConsultado === false) {
                    lista.push(item.CdItemMaterial)
                }
            })

            const payload = {
                Lista: lista.join(','),
                Cpf: this.reseller.Cpf,
                Cnpj: this.reseller.Cnpj,
                IdSistema: this.reseller.Sistema,
            }

            return store.dispatch( 'getPrecos', payload )
                .then( ( prices ) => {

                    // Verificar se veio registros
                    if ( Vue.lodash.isEmpty( prices ) === false ) {

                        // identifica os preços retornados da API em um array associativo
                        const produtosConsultados = {}
                        Vue.lodash.each(prices, item => {
                            produtosConsultados[item.sku] = item
                            if (!(item.valorLiquido > 0)) {
                                this.catalog.error = errorMessage
                            }
                        })

                        // le todos os preços da tela, e que foram enviados para a API da cigam/seta, e procura o correspondente em OBJ
                        // se achar, substitui
                        Vue.lodash.each(produtosConsultados, produtoConsultado => {

                            // Localizar produto dentro da vitrine
                            const produtoLocalizado = Vue.lodash.filter( items, produto => {
                                return produto.CdItemMaterial === produtoConsultado.sku
                            } )

                            // Caso o produto tenha sido localizado, atualizar o objeto
                            Vue.lodash.each(produtoLocalizado, produto => {
                              produto.PrecoCheio = produto.Preco
                              produto.Preco = produtoConsultado.precoLoja
                              produto.precoConsultado = true

                              // Validar se o preço da loja é maior
                              if ( produto.Preco > produto.PrecoCheio ) {
                                produto.Preco = produto.PrecoCheio
                                produto.PrecoCheio = 0
                              }
                            })

                        })

                    }
                    else {
                        Vue.lodash.each(items, produto => {
                            // Caso o produto tenha sido localizado, atualizar o objeto
                            if (Vue.lodash.isEmpty(produto) === false) {
                                produto.precoConsultado = true
                            }
                        })
                        this.catalog.error = errorMessage
                    }

                })
                .catch((error) => {
                    console.log('error', error)
                })

        },
        getAllVitrinesByReseller() {
            // Carregar Demais Vitrines
            store.dispatch('getAllVitrinesByReseller', this.reseller)
                .then(catalogs => {
                    // Identificar outras vitrines
                    this.catalogs = catalogs
                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        // Produtos
        hideSidebarModalProduct() {
            this.showSidebarProduct = false
            this.showModalProduct = false
            this.$bvModal.hide('modal-product')

            // Habilitar scroll da vitrine
            this.disableScrollVitrine(false)

            // Verificar se precisa ir para Vitrine
            if (Vue.lodash.isEmpty(this.$route.params.VitrineResellerId) === false) {
                // Ir para vitrine
                this.$router.push({
                    path: `/vitrine/${this.reseller.VitrineRevendedorID}`,
                })
            }

        },
        viewProduct(CdIteMaterial) {

            // Verificar se o produto está aberto
            if (Vue.lodash.isEmpty(this.$route.params.CdIteMaterial)) {
                // Ir para produto
                this.$router.push({
                    path: `/vitrine/${this.reseller.VitrineRevendedorID}/${CdIteMaterial}`,
                })
            }
            else {
                // Exibir
                if (this.isDesktop()) {
                    // Desktop é Modal
                    this.$bvModal.show('modal-product')
                    this.showModalProduct = true
                }
                else {
                    // Mobile ou iPad é Sidebar
                    this.showSidebarProduct = true
                }

                // Desabilitar scroll da vitrine
                this.disableScrollVitrine(true)

                // Zerar Produto
                this.product = {
                    error: false,
                    estoque: [],
                    estoqueNumeracoes: [],
                    LojasEstoqueAgrupado: [],
                    Cnpj: null,
                    FuncIdo: null,
                    IdSistema: null,
                    VendedorCpf: null,
                    CodLoja: null,
                    Referencia: null,
                    Cor: null,
                    Material: null,
                    FotoPrincipal: null,
                    Numeracao: null,
                    FotoGaleria: null,
                    FotoGaleriaZoom: null,
                    TamanhoSalto: null,
                    TodasFotos: [],
                    OutrasCores: [],
                    Nome: null,
                    CdItemMaterial: null,
                    Descricao: null,
                    Preco: 0,
                    PrecoCheio: 0,
                    PrecoEcommerce: 0,
                    IsPrecoLoja: true,
                }

                // Iniciar busca do Produto
                const payload = {
                    CodLoja: this.reseller.CodLoja,
                    CdItemMaterial: CdIteMaterial,
                    FuncIdo: this.reseller.FuncIdo,
                    VitrineResellerId: this.VitrineResellerId,
                }

                // Iniciar carga
                store.dispatch('GetProductInfo', payload)
                    .then(product => {

                        // Verificar se o produto existe
                        if (Vue.lodash.isEmpty(product)) {
                            this.$router.push({name: 'page-not-found'})
                        }
                        else {

                            // Identificar produto
                            this.product = Vue.lodash.assign(this.product, product)

                            // Valores default
                            this.product.estoque = []
                            this.product.FotoGaleria = this.product.FotoPrincipal
                            this.product.FotoGaleriaZoom = this.product.FotoPrincipal
                            if (Vue.lodash.isEmpty(this.product.TodasFotos)) {
                                this.product.TodasFotos = []
                            }
                            if (Vue.lodash.isEmpty(this.product.OutrasCores)) {
                                this.product.OutrasCores = []
                            }

                            // Se o isPrecoLoja = false
                            if (this.product.IsPrecoLoja === false) {
                                this.product.precoConsultado = false
                                this.getPrecos([this.product])
                                    .then(() => {
                                        // Adicionar produto ao GTM
                                        this.$gtm.trackEvent({
                                            'ecommerce': {
                                                'detail': {
                                                    'products': [
                                                        {
                                                            'name': this.product.Nome,
                                                            'id': this.product.CdItemMaterial,
                                                            'price': this.product.Preco,
                                                            'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                                            // 'category': this.VitrineResellerId,
                                                            // 'variant': this.product.Material,
                                                        },
                                                    ],
                                                },
                                            },
                                        })
                                    })
                            }
                            else {
                                // Adicionar produto ao GTM
                                this.$gtm.trackEvent({
                                    'ecommerce': {
                                        'detail': {
                                            'products': [
                                                {
                                                    'name': this.product.Nome,
                                                    'id': this.product.CdItemMaterial,
                                                    'price': this.product.Preco,
                                                    'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                                    // 'category': this.VitrineResellerId,
                                                    // 'variant': this.product.Material,
                                                },
                                            ],
                                        },
                                    },
                                })
                            }

                            // Carregar estoque
                            this.GetEstoque(this.product.CdItemMaterial)
                                .then(estoque => {

                                    // Identificar estoque
                                    this.product.estoque = Vue.lodash.orderBy(estoque, ['Quantity'], ['desc'])

                                    // Retornar apenas numeracoes unicas
                                    this.product.estoqueNumeracoes = Vue.lodash.reduceRight(this.product.estoque, function (rowFlat, row) {
                                        return rowFlat.concat(row.Size)
                                    }, [])

                                    // Remover numeracoes duplicadas
                                    this.product.estoqueNumeracoes = Vue.lodash.uniq(this.product.estoqueNumeracoes)

                                    // Ordenar array
                                    this.product.estoqueNumeracoes = Vue.lodash.orderBy(this.product.estoqueNumeracoes)

                                    // Identificar produtos com estoque
                                    const qtdEstoque = Vue.lodash.filter(this.product.estoque, estoque => {
                                        return estoque.Quantity > 0
                                    })

                                    // Verificar com os produtos com estoque o tipo de numeracao
                                    this.hasMixedSizes = this.validMixedSizes(
                                        Vue.lodash.reduceRight(qtdEstoque, function (flattened, estoque) {
                                            return flattened.concat(estoque.Size);
                                        }, [])
                                    );

                                    // Remover Loading
                                    this.loadingApp = false

                                })
                                .catch((error) => {
                                    console.log('error', error)
                                })

                        }

                        // Existe um bug que quando existe paginaçao e a modal é ativada junto... a paginacao emit close na modal :/
                        this.showPagination = true
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            }

        },
        viewProductEstoque(evt, product) {

            evt.preventDefault()

            // Verificar se o estoque está em exibição
            if (product.showEstoque === true) {
                // Ocultar
                product.showEstoque = false
            }
            else {
                // Verificar se o estoque já foi consultado
                if (Vue.lodash.isEmpty(product.estoqueNumeracoes) === false) {
                    // Exibir estoque
                    product.showEstoque = true
                }
                else {

                    // Exibir loading
                    product.showEstoque = true
                    product.showLoadingEstoque = true

                    // Consultar numeração disponíveis
                    this.GetEstoque(product.CdItemMaterial)
                        .then(estoque => {
                            // Identificar estoque
                            product.estoque = Vue.lodash.orderBy(estoque, ['Quantity'], ['desc'])

                            // Retornar apenas numeracoes unicas
                            product.estoqueNumeracoes = Vue.lodash.reduceRight(product.estoque, function (rowFlat, row) {
                                return rowFlat.concat(row.Size)
                            }, [])

                            // Remover numeracoes duplicadas
                            product.estoqueNumeracoes = Vue.lodash.uniq(product.estoqueNumeracoes)

                            // Ordenar array
                            product.estoqueNumeracoes = Vue.lodash.orderBy(product.estoqueNumeracoes)

                            // Identificar produtos com estoque
                            const qtdEstoque = Vue.lodash.filter(product.estoque, estoque => {
                                return estoque.Quantity > 0
                            })

                            // Verificar com os produtos com estoque o tipo de numeracao
                            this.hasMixedSizes = this.validMixedSizes(
                                Vue.lodash.reduceRight(qtdEstoque, function (flattened, estoque) {
                                    return flattened.concat(estoque.Size);
                                }, [])
                            );

                            // Remover Loading
                            product.showLoadingEstoque = false
                        })
                        .catch((error) => {
                            console.log('error', error)
                        })
                }
            }

        },
        addProductToBag(evt, product, numeracaoSelecionada) {

            evt.preventDefault()

            // Ocultar estoque
            product.showEstoque = false

            // Exibir loading do Produto
            product.showLoading = true

            // Verificar se o estoque foi consultado
            if (Vue.lodash.isEmpty(product.estoque)) {

                // Consultar estoque
                this.GetEstoque(product.CdItemMaterial)
                    .then(estoque => {

                        // Identificar estoque
                        product.estoque = Vue.lodash.orderBy(estoque, ['Quantity'], ['desc'])

                        // Retornar apenas numeracoes unicas
                        product.estoqueNumeracoes = Vue.lodash.reduceRight(product.estoque, function (rowFlat, row) {
                            return rowFlat.concat(row.Size)
                        }, [])

                        // Remover numeracoes duplicadas
                        product.estoqueNumeracoes = Vue.lodash.uniq(product.estoqueNumeracoes)

                        // Ordenar array
                        product.estoqueNumeracoes = Vue.lodash.orderBy(product.estoqueNumeracoes)

                        // Tentar adicionar produto novamente
                        this.addProductToBag(evt, product, numeracaoSelecionada)

                    })
                    .catch((error) => {
                        console.log('error', error)
                    })

            }
            else {

                // Ler estoque já consultado
                const estoques = Vue.lodash.filter(product.estoque, estoque => {
                    return (
                        estoque.Size === numeracaoSelecionada &&
                        estoque.Quantity > 0
                    )
                })

                // Objeto Loja
                let Loja = {}

                // Verificar se o produto está no Ecommerce
                if (this.isOnlyPrateleiraInfinita(product, numeracaoSelecionada)) {
                    Loja = Vue.lodash.find(estoques, estoque => {
                        return estoque.StoreCode === store.getters.storeCodeEcommerce
                    })
                }
                else {
                    // Verificar se o produto está na loja Matriz
                    const LojaMatriz = Vue.lodash.find(estoques, estoque => {
                        return estoque.StoreCode === this.reseller.CodLoja
                    })
                    if (Vue.lodash.isEmpty(LojaMatriz)) {
                        // Verificar se oproduto está na loja afilhada
                        // Ignorando o Ecommerce
                        Loja = Vue.lodash.find(estoques, estoque => {
                            return (
                                estoque.StoreCode !== store.getters.storeCodeEcommerce &&
                                estoque.Quantity > 0
                            )
                        })
                    }
                    else {
                        // Usar loja matriz
                        Loja = LojaMatriz
                    }
                }

                // Adicionar produto
                if (Vue.lodash.isEmpty(Loja)) {
                    product.showLoading = false
                    alert('Não foi possível localizar loja')
                }
                else {
                    // Criar registro
                    const productSelected = {
                        permitirCompra: true,
                        estoque: product.estoque,
                        VitrineResellerId: (this.reseller.VitrineRevendedorID).toString(),
                        VitrineRevendedorID: (this.reseller.RevId).toString(),
                        storeCode: (this.reseller.CodLoja).toString(),
                        CodLoja: Loja.StoreCode,
                        ean: (Loja.EAN).toString(),
                        quantidade: 1,
                        CdItemMaterial: product.CdItemMaterial,
                        size: Loja.Size,
                        Nome: product.Nome,
                        Preco: this.isOnlyPrateleiraInfinita(product, numeracaoSelecionada) ? product.PrecoEcommerce : product.Preco,
                        PrecoCheio: product.PrecoCheio,
                        FotoPrincipal: product.FotoUrl,
                        Numeracao: numeracaoSelecionada,
                        FotoGaleria: product.FotoUrl,
                        FotoGaleriaZoom: product.FotoUrl,
                    }

                    // Adicionar produto ao carrinho
                    store.dispatch('addToBag', productSelected)
                        .then(() => {
                            product.showLoading = false
                            // Atualizar carrinho
                            this.loadItemsBag()

                            // abrir sidebar bag
                            this.openSidebarBag = true

                            // Adicionar ao GTM
                            this.$gtm.trackEvent({
                                'event': 'addToCart',
                                'ecommerce': {
                                    'currencyCode': 'BRL',
                                    'add': {
                                        'products': [
                                            {
                                                'name': this.product.Nome,
                                                'id': this.product.CdItemMaterial,
                                                'price': this.product.Preco,
                                                'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                                // 'category': this.VitrineResellerId,
                                                'variant': numeracaoSelecionada,
                                                'quantity': 1,
                                            },
                                        ],
                                    },
                                }
                            })
                        })
                        .catch((error) => {
                            product.showLoading = false
                            console.log('error', error)
                        })

                }
            }

        },
        isOnlyPrateleiraInfinita(product, numeracao) {
            // Ler estoque para encontrar todas as lojas que tem numeracao e estoque disponivel
            const estoqueDisponivel = Vue.lodash.filter(product.estoque, estoque => {
                return (
                    estoque.Size === numeracao &&
                    estoque.Quantity > 0
                )
            })
            // Caso venha apenas um registro, verificar se é ecommerce
            if (estoqueDisponivel.length === 1) {
                // Leitura se é ecommerce
                const ecommerce = Vue.lodash.find(estoqueDisponivel, estoque => {
                    return estoque.StoreCode === store.getters.storeCodeEcommerce
                })
                // é ecommerce ?
                return !Vue.lodash.isEmpty(ecommerce)
            }
            // default
            return false
        },
        shareProduct(evt, product) {

            evt.preventDefault()

            // Montar mensagem
            let whatsAppMessage = 'Oie olha só o que eu achei!%0D%0A';
            whatsAppMessage += `${window.location.href}/${product.CdItemMaterial}%0D%0A`; // Link do produto
            whatsAppMessage += `${ product.Nome }%0D%0A`; // Nome do Produto
            whatsAppMessage += product.CdItemMaterial; // SKU

//            if (navigator.share && this.isMobile()) {
//                // Share
//                navigator.share({
//                    title: 'ZZVendas',
//                    text: whatsAppMessage,
//                    url: document.location.href,
//                })
//            }
//            else {
                // Enviar ao WhatsApps
                window.open(`https://api.whatsapp.com/send?text=${whatsAppMessage}`);
                // this.sendWhatsappToReseller(this.reseller, whatsAppMessage)
//            }
        },
        GetEstoque(CdItemMaterial) {

            // Consultar numeração disponíveis
            let storeCode = (this.reseller.CodLoja).toString()
            if (this.reseller.LojasEstoqueAgrupado.length > 0) {
                storeCode = storeCode.concat(',').concat(this.reseller.LojasEstoqueAgrupado.join(','))
            }
            // DEBUG para forçar ecommerce
            // storeCode = storeCode.concat(',300979')

            // Carga de informações
            const payload = {
                storeCode: storeCode,
                sku: CdItemMaterial,
            }

            return store.dispatch('GetEstoque', payload)
        },
        disableByEstoque(produto, numeracao) {
            let estoqueDisponivel = Vue.lodash.find(produto.estoque, estoque => {
                return (
                    estoque.Size === numeracao &&
                    estoque.Quantity > 0
                )
            })
            if (estoqueDisponivel === undefined) {
                return true
            }
            else {
                // Verificar se é Prateleira Infinita
                // Se for, verificar se PrecoEcommerce > 0
                if (this.isOnlyPrateleiraInfinita(produto, numeracao)) {
                    return produto.PrecoEcommerce === 0;
                }
            }

            return false
        },
        // Bag
        changeSidebarMenuStatus(status) {
            this.openSidebarBag = status
        },
        loadItemsBag(closeModal = true) {

            const productIsOpen = (this.showSidebarProduct === true || this.showModalProduct === true)
            if (productIsOpen && closeModal) {
                this.hideSidebarModalProduct()
            }

            store.dispatch('loadBag', this.reseller)
                .then(bag => {
                    this.bag = bag
                    if (productIsOpen && closeModal) {
                        this.openSidebarBag = true
                    }
                })
        },
    },
    watch: {
        reseller() {
            if (Vue.lodash.isEmpty(this.reseller) === false) {
                // Identificar o nome do Revendedor
                document.title = 'LOJA '.concat(this.reseller.NomeRevendedora)

                // Identificar marca
                let htmlElement = document.documentElement
                htmlElement.setAttribute('theme', 'marca_'.concat(this.reseller.MarcaIdo))
            }
        },
        // Vitrine mudou via site
        // Não precisa fazer releitura do Revendedor...
        // As rotinas da vitrine SEMPRE devem relacionar ao revendedor da "Sessao"
        // Caso seja alterado via URL "na mão", a página é recarregada
        '$route.params.VitrineResellerId': function (VitrineAtual, VitrineAnterior, a) {
            // Carregar informacoes da nova vitrine
            this.reseller.VitrineRevendedorID = this.VitrineResellerId
            // Zerar variaveis
            this.clearMemory()
            // Carregar produtos
            this.getAllProductsByReseller(1)
            // Carregar filtros
            this.GetFiltrosByVitrine()
        },
        '$route.params.CdIteMaterial': function (CdIteMaterial) {
            if (Vue.lodash.isEmpty(CdIteMaterial) === false) {
                this.viewProduct(CdIteMaterial)
            }
        },
    },
    computed: {
        getEstoqueArea() {
            const widthPrecoAtualList = document.getElementsByClassName('preco_atual')
            if (Vue.lodash.isEmpty(widthPrecoAtualList)) {
                return 0
            }
            const widthPrecoAtual = Vue.lodash.first(widthPrecoAtualList)
            return widthPrecoAtual.clientWidth
        },
        getUnicoTamanhoFiltrado() {

            // Validar se tem Tamanho Filtrado
            if (Vue.lodash.isEmpty(this.filtred.tamanhos)) {
                return null
            }
            // Verificar se tem mais de um tamanho Filtrado
            else if (this.filtred.tamanhos.length > 1) {
                return null
            }

            // Retornar tamanho único filtrado
            return Vue.lodash.first(this.filtred.tamanhos)
        },
    },
    mounted() {

        // Exibir Modal Promocional cada vez que entrar na página
        this.showPromotionalModal();

        // Carregar informacoes do vendedor
        this.GetInfo()

        // Setar plataforma acessada
        document.body.classList.add('platform-'.concat(navigator.platform))

        // Setar desktop platform
        if (this.isDesktop()) {
            document.body.classList.add('is-desktop')
        }
    },
}
</script>
