<template>
    <div style="height: 60px">
        <b-navbar ref="headerbar" id="headerbar" toggleable="lg" type="dark" variant="dark" class="justify-content-center fixed-top">
            <b-navbar-brand href="#">
                <b-button v-b-toggle.sidebar-menu id="btnMenu" size="lg" variant="transparent">
                    <b-icon icon="list" aria-hidden="true"></b-icon>
                </b-button>
            </b-navbar-brand>
            <b-img-lazy id="logo"
                        alt="Logo" fluid
                        v-if=" this.reseller.MarcaIdo "
                        :class=" `logo_${this.reseller.MarcaIdo}` "
                        :src=" getLogo( this.reseller.MarcaIdo ) "
            ></b-img-lazy>
            <div id="search" class="cursor-pointer" v-on:click="toogleHeaderbarSearch">
                <b-icon v-if="showHeaderbarSearch" icon="x"></b-icon>
                <b-icon v-else icon="search"></b-icon>
            </div>
            <b-button v-b-toggle.sidebar-bag size="sm" variant="transparent" v-if="reseller.FuncIdo" id="cartBtn" class="cursor-pointer">
                <b-img :src=" getIconBag(reseller.MarcaIdo) " fluid alt=" Compartilhar "></b-img>
                <div id="bagQtd">{{ bag.length }}</div>
            </b-button>
        </b-navbar>
        <div class=" fixed-top" id="headerbar-search" v-show="showHeaderbarSearch">
            <b-form @submit="buscarProduto" fluid>
                <b-input-group>
                    <template v-slot:prepend>
                        <b-input-group-text>
                            <b-icon icon="search"></b-icon>
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model=" query " placeholder="Buscar"></b-form-input>
                </b-input-group>
            </b-form>
        </div>
        <b-sidebar
            id="sidebar-menu"
            no-header-close shadow
            backdrop
            backdrop-variant="transparent"
            :no-header=" isDesktop() "
            @change=" disableScrollVitrine "
        >
            <!-- Header -->
            <template v-slot:title="{}">

                <!-- HEADER -->
                <div class="b-sidebar-header">
                    <div class="w-100 h-100 text-center">
                        <b-img :src=" getFixLogo(reseller.MarcaIdo) " fluid alt=" Compartilhar "></b-img>
                    </div>
                </div>

            </template>

            <!-- Filtros e Destaques -->
            <b-list-group>
                <!-- CATEGORIAS -->
                <template v-if="this.filtros.Categorias.length > 0">
                    <b-list-group-item @click="collapse.categorias = !collapse.categorias" :class="collapse.categorias ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        CATEGORIAS
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-categorias" v-model="collapse.categorias">
                        <b-list-group-item
                            v-for="categoria in categoriasOrdenadas"
                            v-bind:key=" `categorias_${categoria.CodCategoria}` "
                        >
                            <b-form-checkbox @change=" setFilters( $event,  'categorias', categoria.CodCategoria ) ">
                                {{ categoria.DescCategoria }}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- CORES -->
                <template v-if="this.filtros.Cores.length > 0">
                    <b-list-group-item @click="collapse.cores = !collapse.cores" :class="collapse.cores ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        CORES
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-cores" v-model="collapse.cores">
                        <b-list-group-item
                            v-for="cor in coresOrdenadas"
                            v-bind:key=" `cores_${cor.Cor}` "
                        >
                            <b-form-checkbox @change=" setFilters( $event,  'cores', cor.Cor ) ">
                                {{ cor.Cor }}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- TamanhosBolsas -->
                <template v-if="this.filtros.TamanhosBolsas.length > 0">
                    <b-list-group-item @click="collapse.tamanhos_bolsas = !collapse.tamanhos_bolsas" :class="collapse.tamanhos_bolsas ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        TAMANHO DA BOLSA
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-tamanhos_bolsas" v-model="collapse.tamanhos_bolsas">
                        <b-list-group-item
                            v-for="item in tamanhoDeBolsasOrdenadas"
                            v-bind:key=" `tamanhos_bolsas_${item.TamBolsa}` "
                        >
                            <b-form-checkbox @change=" setFilters( $event,  'tamanhos_bolsas', item.TamBolsa ) ">
                                {{ item.TamBolsa }}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- TamanhosSaltos -->
                <template v-if="this.filtros.TamanhosSaltos.length > 0">
                    <b-list-group-item @click="collapse.tamanhos_saltos = !collapse.tamanhos_saltos" :class="collapse.tamanhos_saltos ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        TAMANHO DO SALTO
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-tamanhos_saltos" v-model="collapse.tamanhos_saltos">
                        <b-list-group-item
                            v-for="item in tamanhoDeSaltosOrdenados"
                            v-bind:key=" `tamanhos_saltos_${item.TamSalto}` "
                        >
                            <b-form-checkbox @change=" setFilters( $event,  'tamanhos_saltos', item.TamSalto ) ">
                                {{ item.TamSalto }}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- Material -->
                <template v-if="this.filtros.Material.length > 0">
                    <b-list-group-item @click="collapse.material = !collapse.material" :class="collapse.material ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        MATERIAL
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-material" v-model="collapse.material">
                        <b-list-group-item
                            v-for="item in materiaisOrdenados"
                            v-bind:key=" `material_${item.Material}` "
                        >
                            <b-form-checkbox @change=" setFilters( $event,  'material', item.Material ) ">
                                {{ item.Material }}
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- PREÇOS -->
                <template v-if="this.isDesktop() && false">
                    <b-list-group-item @click="collapse.precos = !collapse.precos" :class="collapse.precos ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        PREÇOS
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-precos" v-model="collapse.precos">
                        <label>De</label>
                        <vue-slider
                            :tooltip="'always'"
                            :max=" 1000 "
                            :data=" filtred.precos_range "
                            v-model="filtred.precos_base"
                            @drag-end=" $emit('applyFilterPrice') "
                        />
                        <label>Até</label>
                    </b-collapse>
                </template>
                <!-- ORDERNAR -->
                <template v-if="this.isDesktop()">
                    <b-list-group-item @click="collapse.order_by = !collapse.order_by" :class="collapse.order_by ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        ORDENAR
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-collapse id="collapse-order_by" v-model="collapse.order_by">
                        <b-list-group-item>
                            <b-form-group>
                                <b-form-radio-group stacked
                                                    id="filtred-order_by"
                                                    name="filtred-order_by"
                                                    v-model="filtred.order_by"
                                                    :options="filtred.order_by_options"
                                                    @input=" setFilters( $event,  'order_by_fake', null ) "
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-list-group-item>
                    </b-collapse>
                </template>
                <!-- TAMANHOS -->
                <template v-if="this.filtros.Tamanhos.length > 0 && this.isDesktop()">
                    <b-list-group-item @click="collapse.tamanhos = !collapse.tamanhos" :class="collapse.tamanhos ? 'not-collapsed' : 'collapsed'" class="title cursor-pointer">
                        TAMANHOS
                        <b-icon-plus></b-icon-plus>
                        <b-icon-dash></b-icon-dash>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-collapse id="collapse-tamanhos" v-model="collapse.tamanhos">
                            <b-container fluid>
                                <b-row style="padding: 10px">
                                    <b-col cols="3" class="p-1"
                                           v-for="tamanho in this.filtros.Tamanhos"
                                           v-bind:key=" `tamanhos_${tamanho.Tamanho}` "
                                    >
                                        <b-button
                                            class="btnSize"
                                            :class="isSizeSelected( tamanho.Tamanho ) ? 'active' : ''"
                                            @click=" setFilters( $event,  'tamanhos', tamanho.Tamanho ) "
                                        >
                                            {{ tamanho.Tamanho }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-collapse>
                    </b-list-group-item>
                </template>
                <!-- DESTAQUES -->
                <template v-if="destaques.length > 0">
                    <b-list-group-item class="title">DESTAQUES</b-list-group-item>
                    <template v-for="destaque in destaques">
                        <b-list-group-item
                            v-bind:key="destaque.VitrineID"
                            v-if="destaque.VitrineNome"
                            class=" outras-vitrines text-truncate cursor-pointer "
                            @click=" $emit('goToVitrine', `/vitrine/${ destaque.VitrineID }`, destaque.VitrineID == reseller.VitrineRevendedorID) "
                        >
                            {{ destaque.VitrineNome }}
                        </b-list-group-item>
                    </template>
                </template>
            </b-list-group>

        </b-sidebar>
        <b-sidebar
            id="sidebar-bag"
            v-model=" sidebarBagIsOpen "
            aria-controls="id"
            right no-header-close shadow
            backdrop
            backdrop-variant="transparent"
            @change=" changeSidebarMenuStatus "
        >
            <template v-slot:title="{ hide }">

                <!-- HEADER -->
                <div class="b-sidebar-header">
                    <button type="button" aria-label="Close" class="close text-dark" @click="hide">
                        <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi">
                            <g>
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                            </g>
                        </svg>
                    </button>
                    <div class="w-100 h-100 text-center">
                        <b-img :src=" getFixLogo(reseller.MarcaIdo) " fluid alt=" Compartilhar "></b-img>
                    </div>
                </div>

                <!-- SUBHEADER -->
                <div class="b-sidebar-subheader">
                    <b-button size="sm" variant="transparent">
                        <b-img :src=" getIconBag('black') " style="width: 19px; height: 19px" fluid alt=" Comprar "></b-img>
                        SACOLA
                    </b-button>
                </div>

            </template>
            <!-- ITEMS -->
            <template v-if="bag.length > 0">
                <b-overlay class="h-100" :show=" showOverlayAtendimento ">
                    <b-container id="bagList" fluid>
                        <b-row
                            v-for="product in bag"
                            v-bind:key=" product.ean "
                        >
                            <b-col class="bagProductLeft" cols="4">
                                <b-img
                                    :src=" product.FotoPrincipal | resizeImage "
                                    @error="replaceByDefaultSmall"
                                ></b-img>
                            </b-col>
                            <b-col class="bagProductRight" cols="8">
                                <p class="produto_nome text-truncate">{{ product.Nome }}</p>
                                <p class="produto_tamanho">Tam: {{ product.size }}</p>
                                <b-form-spinbutton
                                    class="produto_quantidade"
                                    min="0"
                                    :value=" product.quantidade "
                                    :max=" getMaxEstoqueDisponivel( product ) "
                                    @change=" updateQuantidade( $event, product ) "
                                >
                                </b-form-spinbutton>
                                <p class="produto_preco">{{ product.Preco | numberToBRL }}</p>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-overlay>
            </template>
            <template v-else>
                <b-container id="bagList-empty" class="h-100">
                    <b-row class="h-100">
                        <b-col class="h-100">
                            <p class=" text-bold ">SUA SACOLA ESTÁ VAZIA</p>
                            <p class=" text-small ">TUDO BEM, VAMOS VER PELO LADO POSITIVO:</p>
                            <p class=" text-small ">ASSIM CABEM MAIS PRODUTOS ;)</p>
                            <template v-if=" isVitrineTodosProdutos ">
                                <b-button v-b-toggle.sidebar-bag>
                                    DESCOBRIR OFERTAS
                                </b-button>
                            </template>
                            <template v-else>
                                <b-button @click=" irParaVitrineTodosProdutos ">
                                    DESCOBRIR OFERTAS
                                </b-button>
                            </template>

                        </b-col>
                    </b-row>
                </b-container>
            </template>
            <template v-slot:footer="" v-if="bag.length > 0">
                <b-overlay class="h-100" :show=" showOverlayAtendimento ">
                    <b-form @submit="doCompleteAttendence" fluid>
                        <!-- Email -->
                        <b-form-group
                            id="group-nome"
                            label="NOME"
                            label-for="nome"
                            label-cols="2"
                        >
                            <b-form-input
                                id="nome"
                                v-model="form.nome.value"
                                type="text"
                                required
                                :state="validarNome"
                                maxlength="60"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarNome">
                                Mínimo 3 caracteres
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- CPF -->
                        <b-form-group
                            id="group-cpf"
                            label="CPF"
                            label-for="cpf"
                            label-cols="2"
                        >
                            <b-form-input
                                id="cpf"
                                v-model="form.cpf.value"
                                required
                                :state="validarCPF"
                                v-mask="'###.###.###-##'"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarCPF">
                                CPF é inválido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- Total do pedido -->
                        <b-form-group
                            id="group-total"
                            label="TOTAL A PAGAR"
                            label-for="total"
                            label-cols="5"
                        >
                            <b-form-input
                                id="total"
                                required
                                disabled
                                :value=" total | numberToBRL "
                            ></b-form-input>
                        </b-form-group>
                        <!-- Botões de ação -->
                        <b-button
                            type="submit"
                            block
                            :disabled="bloquearNovoAtendimento"
                        >
                            COMPRAR
                        </b-button>
                    </b-form>
                </b-overlay>
            </template>
        </b-sidebar>
        <b-modal id="modal-preatendimento" size="sm"
                 centered
                 hide-header hide-footer
                 no-close-on-backdrop
                 no-close-on-esc
                 no-enforce-focus
        >
            <template v-if=" isAttendenceZZLink ">
                <p class="title">PARABÉNS!</p>
                <p class="small">
                    Excelente escolha. Selecione abaixo <br/>
                    como deseja finalizar sua compra.
                </p>
                <b-button @click=" sendWhatsapp ">
                    FINALIZAR COM A VENDEDORA
                    <img src="../../assets/img/logo-whatsapp.png" alt="Whatsapp Logo"/>
                </b-button>
                <b-button variant="zzlink" @click=" openZZLink ">
                    IR PARA PAGAMENTO
                </b-button>
            </template>
            <template v-else>
                <p class="title">PARABÉNS!</p>
                <p class="small">
                    Excelente escolha! Sua compra será <br/>
                    finalizada pelo WhatsApp!
                </p>
                <b-button @click=" sendWhatsapp ">
                    FINALIZAR COMPRA
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<style lang="scss">

#headerbar
{
    height: 60px;
    background-color: var(--header-background-color) !important;

    .navbar-brand
    {
        position: absolute !important;
        left: 5px;

        #btnMenu
        {
            color: var(--icon-color);

            svg
            {
                font-size: 40px;
            }
        }
    }

    #logo
    {
        margin: 0;
        height: 15px;

        &.logo_2
        {
            height: 17px;
        }
    }

    #search
    {
        color: var(--cart-color);
        font-size: 30px;
        position: absolute;
        display: grid;
        height: 100%;
        align-items: center;
        left: 70px;
    }

    #cartBtn
    {
        position: absolute;
        padding: 0;
        top: 10px;
        right: 10px;
        background-color: var(--header-background-color) !important;
        border: 0;
        color: var(--cart-color);
        font-size: 30px;

        img
        {
            width: 31px;
            margin-top: -15px;
        }

        #bagQtd
        {
            position: absolute;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            margin-bottom: 0;
            margin-left: -5px;
            text-align: center;
            top: 2px;
            right: -7px;
            background-color: white;
            color: black;
            font-size: 10px;
            font-weight: bold;
        }

    }
}

#sidebar-menu
{
    height: 100% !important;

    header
    {
        z-index: 20;
        padding: 0;
        box-shadow: 0 1px 3px #0000001A;
        height: 64px;

        strong
        {
            width: 100%;

            .b-sidebar-header
            {
                height: 50px;

                .text-center
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img
                    {
                        height: 15px;
                    }
                }
            }
        }
    }

    .b-sidebar-body
    {
        .list-group
        {
            border-radius: 0;

            .title
            {
                height: 38px;
                background-color: var(--selected-background-color);
                color: var(--selected-text-color);
                padding: 0 0 0 23px;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: bold;

                .b-icon
                {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }

            .list-group-item
            {
                font-size: 14px;

                .btnSize
                {
                    margin-bottom: 5px;
                }
            }

            .list-group-item:not(.disabled):not(.text-truncate)
            {
                border: none;
                padding-top: 0;
                padding-bottom: 0;

                .custom-control
                {
                    height: 30px;
                    padding-left: 46px;
                    display: flex;
                    align-items: center;

                    label
                    {
                        font-size: 14px;
                        font-weight: bold;
                        width: 100%;
                    }
                }
            }

            .outras-vitrines.router-link-exact-active
            {
                background-color: #dfe6e9 !important;
            }

            .not-collapsed
            {
                .bi-plus
                {
                    display: none
                }
            }

            .collapsed
            {
                .bi-dash
                {
                    display: none
                }
            }
        }
    }

    .form-group
    {
        margin-bottom: 0;
    }
}

.is-desktop
{
    #sidebar-menu
    {
        margin-top: 130px !important;

        .b-sidebar-body
        {
            .list-group
            {
                padding-bottom: 130px;

                #collapse-precos
                {
                    display: flex;
                    padding: 5px;
                    height: 50px;

                    label
                    {
                        font-size: 15px;
                        line-height: 47px;
                    }

                    .vue-slider
                    {
                        width: 230px !important;
                        padding: 7px 15px !important;
                        padding-top: 22px !important;

                        .vue-slider-rail
                        {
                            .vue-slider-process
                            {
                                background-color: black;
                            }

                            .vue-slider-dot
                            {
                                .vue-slider-dot-handle
                                {
                                    border-color: black;
                                }

                                .vue-slider-dot-tooltip
                                {
                                    top: 0;

                                    .vue-slider-dot-tooltip-inner
                                    {
                                        padding: 0;
                                        background: transparent;
                                        border-radius: unset;
                                        border-color: transparent;
                                        box-shadow: none;

                                        .vue-slider-dot-tooltip-text
                                        {
                                            font-size: 10px;
                                            color: black;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#sidebar-bag
{
    height: 100% !important;

    header
    {
        z-index: 20;
        padding: 0;
        box-shadow: 0 1px 3px #0000001A;
        height: 110px;

        strong
        {
            width: 100%;

            .b-sidebar-header
            {
                background-color: #F1F1F1;
                font-size: 1.5rem;
                padding: .5rem 1rem;
                display: flex;
                flex-direction: row;
                flex-grow: 0;
                align-items: center;
                height: 50px;

                .close
                {
                    float: none;
                }

                img
                {
                    height: 15px;
                }
            }

            .b-sidebar-subheader
            {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .b-sidebar-body
    {
        background-image: url("../../assets/img/icon-bag-gray.png");
        background-repeat: no-repeat;
        background-position: 100px center;
        background-size: 100% auto;

        #bagList
        {
            padding: 0;

            .row
            {
                box-shadow: 0 1px 3px #00000012;
                background-color: white;
                margin-bottom: 5px;
                margin-left: 0;
                width: 100%;

                .bagProductLeft,
                .bagProductRight
                {
                    height: 100px;
                    padding: 0;
                }

                .bagProductLeft
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img
                    {
                        width: 100%;
                        border-radius: 12px;
                    }
                }

                .bagProductRight
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    p
                    {
                        width: 100%;
                        margin: 0
                    }

                    .produto_nome
                    {
                        font-size: 12px
                    }

                    .produto_tamanho
                    {
                        font-size: 12px
                    }

                    .produto_quantidade
                    {
                        width: 115px;
                        height: 20px;
                        border: 0;

                        button:first-of-type
                        {
                            border-top-left-radius: 5px !important;
                            border-bottom-left-radius: 5px !important;
                            border: 1px solid #ced4da !important;
                        }

                        output
                        {
                            border: none !important;
                            font-size: 12px
                        }

                        button:last-of-type
                        {
                            border-top-right-radius: 5px !important;
                            border-bottom-right-radius: 5px !important;
                            border: 1px solid #ced4da !important;
                        }
                    }

                    .produto_preco
                    {
                        font-size: 14px;
                        font-weight: bold
                    }
                }
            }
        }

        #bagList-empty
        {
            .col
            {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p
                {
                    margin: 0
                }

                p.text-bold
                {
                    font-size: 16px;
                    font-weight: bold;
                }

                p.text-small
                {
                    font-size: 10px;
                }

                button
                {
                    margin-top: 25px;
                    border-radius: 14px;
                    background-color: var(--selected-background-color);
                    color: var(--selected-text-color);
                    width: 100%;
                }
            }
        }

    }

    footer
    {
        padding-bottom: 30px;
        padding-right: 5px;
        padding-left: 5px;

        form
        {
            padding-top: 10px;

            label
            {
                font-size: 12px;
                display: flex;
                align-items: center;
            }

            input
            {
                border: none;
                border-bottom: 1px solid #ebebeb;
                background-color: transparent;
                border-radius: 0;
            }

            button
            {
                border-radius: 14px;
                background-color: var(--selected-background-color) !important;
                color: var(--selected-text-color) !important;
            }

            #total
            {
                background-color: transparent;
                border: none;
            }
        }
    }
}

// Safari Hack
@media not all and (min-resolution: .001dpcm)
{
    @supports (-webkit-appearance:none)
    {
        #sidebar-bag
        {
            header
            {
                height: 170px;
            }
        }
    }
}

#headerbar-search
{
    //position: relative;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #F2F2F2;
    border-bottom: 1px solid #d2d0d0;
    box-shadow: 0 0 10px #00000029;
    z-index: 200;
    top: 60px;

    *
    {
        border: 0;
        background-color: transparent;
    }
}

#modal-preatendimento
{
    .modal-dialog
    {
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-content
        {
            width: 328px;
            text-align: center;
            border-radius: 23px;
            border: none;

            p
            {
                margin: 0
            }

            p.title
            {
                margin-top: 30px;
                font-size: 16px;
                font-weight: bold;
            }

            p.small
            {
                font-size: 14px;
            }

            button
            {
                width: 100%;
                margin-top: 30px;
                border-radius: 14px;
                background-color: #26971A;
                border: none;
                box-shadow: 0 3px 6px #00000029;

                img{
                    height: 20px;
                    margin-left: 10px;
                }

                &.btn-zzlink {
                    background-image: url("../../assets/img/button-bg-zzlink.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: black !important;
                    color: white;
                }
            }
        }
    }
}
</style>
<script>
import Vue from 'vue'
import store from '@/store'
import {Utils} from '../utils'

// Mask
import {VueMaskDirective} from 'v-mask'

Vue.directive('mask', VueMaskDirective)

// Slider do preço
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    name: 'headerbar',
    props: ['reseller', 'filtros', 'filtred', 'destaques', 'bag', 'sidebarBagIsOpen', 'goToVitrine'],
    components: {VueSlider},
    mixins: [Utils],
    data() {
        return {
            // Filtros
            showHeaderbarSearch: false,
            query: '',
            collapse: {
                categorias: false,
                cores: false,
                tamanhos_bolsas: false,
                tamanhos_saltos: false,
                material: false,
                precos: false,
                order_by: false,
                tamanhos: true,
            },
            // Bag
            atendimentos: [],
            showOverlayAtendimento: false,
            zzlinkUrl: '',
            bloquearNovoAtendimento: false,
            form: {
                nome: {
                    value: '',
                    error: null,
                },
                cpf: {
                    value: '',
                    error: null,
                },
            },
            isAttendenceZZLink: false,
        }
    },
    methods: {
        isSizeSelected(value) {
            // Procurar dentro dos itens filtrados
            return Vue.lodash.indexOf(this.filtred.tamanhos_selecionados, value) >= 0
        },
        irParaVitrineTodosProdutos() {
            const VitrineTodosProdutos = Vue.lodash.first(this.destaques)
            this.$router.push({
                path: `/vitrine/${VitrineTodosProdutos.VitrineID}`,
            })
        },
        getFixLogo(MarcaIdo) {
            let logo = this.getLogo(MarcaIdo)
            if (MarcaIdo === 1) {
                logo = require('../../assets/logos/arezzo-preto.png')
            }
            else if (MarcaIdo === 2) {
                logo = require('../../assets/logos/schutz-preto.png')
            }
            else if (MarcaIdo === 6) {
                logo = require('../../assets/logos/fiever.png')
            }
            return logo
        },
        toogleHeaderbarSearch() {
            this.showHeaderbarSearch = !this.showHeaderbarSearch
        },
        setFilters(evt, type, value) {

            if (type === 'tamanhos') {
                // Verificar se tamanho já foi selecionado
                evt = Vue.lodash.indexOf(this.filtred.tamanhos_selecionados, value) < 0
                if (evt) {
                    this.filtred.tamanhos_selecionados.push(value)
                }
                else {
                    this.filtred.tamanhos_selecionados = Vue.lodash.without(this.filtred.tamanhos_selecionados, value)
                }
            }
            if (type === 'order_by_fake') {
                value = evt;
                evt = false
            }

            this.$emit('setFilters', type, value, evt)

        },
        buscarProduto(evt) {

            evt.preventDefault()
            this.setFilters(true, 'pesquisa', this.query)

        },
        doCompleteAttendence(evt) {

            evt.preventDefault()

            // Carregar informações
            const payload = {}
            payload.Nome = this.form.nome.value
            payload.Cpf = this.form.cpf.value
            payload.FuncIdo = this.reseller.FuncIdo
            payload.CodLoja = this.reseller.CodLoja
            payload.MarcaIdo = this.reseller.MarcaIdo

            // Ler carrinho para identificar quais atendimentos criar
            let prateleiraInfinita = []
            let vendaLink = []
            this.lodash.each(this.bag, (product) => {
                const produto = {
                    CdItemMaterial: product.CdItemMaterial,
                    EAN: product.ean,
                    Preco: product.PrecoCheio,
                    PrecoDesconto: product.Preco,
                    Qtde: product.quantidade,
                    Tamanho: product.size,
                    CodLoja: product.CodLoja,
                }
                if (product.CodLoja === store.getters.storeCodeEcommerce) {
                    prateleiraInfinita.push(produto)
                }
                else {
                    vendaLink.push(produto)
                }
            })

            // Verificar se precisa criar
            if (Vue.lodash.isEmpty(prateleiraInfinita) && Vue.lodash.isEmpty(vendaLink)) {
                alert('Nao foi possível identificar o carrinho')
            }
            else {

                // Validações
                this.form.nome.error = payload.Nome.length < 3
                this.form.cpf.error = this.isCPFValid(this.form.cpf.value) === false

                // Não havendo erro, prosseguir
                if (!this.form.nome.error && !this.form.cpf.error) {

                    let AtendimentosPromises = []

                    // Criar atendimento Venda Link
                    if (!Vue.lodash.isEmpty(vendaLink)) {
                        let AtendimentoVendaLink = Vue.lodash.clone(payload)
                        AtendimentoVendaLink.Itens = vendaLink
                        AtendimentosPromises.push(new Promise(resolve => {
                            resolve(store.dispatch('checkout', AtendimentoVendaLink));
                        }))
                    }

                    // Criar atendimento Prateleira Infinita
                    if (!Vue.lodash.isEmpty(prateleiraInfinita)) {
                        let AtendimentoPrateleiraInfinita = Vue.lodash.clone(payload)
                        AtendimentoPrateleiraInfinita.Itens = prateleiraInfinita
                        AtendimentosPromises.push(new Promise(resolve => {
                            const delay = AtendimentosPromises.length * 1000;
                            setTimeout(() => {
                                resolve(store.dispatch('checkout', AtendimentoPrateleiraInfinita));
                            }, delay);
                        }))
                    }

                    // Ativar overlay
                    this.showOverlayAtendimento = true

                    // Criar atendimentos
                    let atendimentosGerados = [];
                    Promise.all(AtendimentosPromises).then(atendimentos => {

                        // Ler atendimentos criados
                        Vue.lodash.each(atendimentos, atendimento => {

                            if (atendimento.IdAtendimento > 0) {
                                // Ler Produtos
                                const produtos = [];
                                Vue.lodash.each(atendimento.Produtos, produto => {
                                    produtos.push({
                                        'name': produto.NomeProduto,     // Name or ID is required.
                                        'id': produto.SKU,
                                        'price': produto.Valor,
                                        'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                        // 'category': this.$route.params.VitrineResellerId,
                                        'variant': produto.Tamanho,
                                        'quantity': produto.Quantidade,
                                        // 'coupon': '', // Optional fields may be omitted or set to empty string.
                                    });
                                })

                                let actionField = {
                                    'id': atendimento.IdAtendimento, // Transaction ID. Required for purchases and refunds.
                                    'affiliation': '',
                                    'revenue': 0.00, // Total transaction value (incl. tax and shipping)
                                    'tax': 0.00,
                                    'shipping': 0.00,
                                    'coupon': ''
                                };
                                actionField.revenue = this.lodash.reduce(produtos, function (memo, product) {
                                    return memo + (product.price * product.quantity)
                                }, 0)

                                // Adicionar ao GTM
                                this.$gtm.trackEvent({
                                    'ecommerce': {
                                        'purchase': {
                                            'actionField': actionField,
                                            'products': produtos
                                        }
                                    }
                                })

                                // Atendimento okay
                                atendimentosGerados.push(atendimento);
                            }

                        });

                        // Alterar estado
                        this.atendimentos = atendimentosGerados
                        this.bloquearNovoAtendimento = true

                        // Avisar fim de atendimento
                        this.isAttendenceZZLink = this.reseller.IsZZLinkEnabled === 'S' && Vue.lodash.isEmpty(prateleiraInfinita);

                        // Bloquear ZZLink para as lojas abaixo
                        const ZZLinkBloqueado = [ 300621 , 304060 ];
                        if(Vue.lodash.includes(ZZLinkBloqueado, this.reseller.CodLoja)){
                            this.isAttendenceZZLink = false;
                        }

                        // Gerar atendimento
                        if(this.isAttendenceZZLink)
                        {
                            this.generateZZLink();
                        }else{
                            this.showOverlayAtendimento = false
                            this.$bvModal.show('modal-preatendimento');
                        }
                    })
                }
            }
        },
        openZZLink(){
            this.clearBag();
            window.open( this.zzlinkUrl )
        },
        generateZZLink() {
            // Ler atendimentos criados
            this.zzlinkUrl = '';
            Vue.lodash.each(this.atendimentos, atendimento => {
                if (atendimento.IdAtendimento > 0) {
                    // Gerar Link
                    store.dispatch('createLink', atendimento.IdAtendimento ).then(response=>{
                        // Fechar loading
                        this.showOverlayAtendimento = false;
                        if( response.Success )
                        {
                            this.zzlinkUrl = response.Result;
                            // Abrir janela ZZLink
                            this.$bvModal.show('modal-preatendimento');
                        } else {

                            // Desbloquear criação de atendimento
                            this.bloquearNovoAtendimento = false;

                            // Fechar janela
                            this.$bvModal.hide('modal-preatendimento');

                            alert( response.Message )
                        }
                    })
                }
            });
        },
        sendWhatsapp() {
            let whatsAppMessage = 'Olá, seguem os produtos que eu escolhi: \n'

            // Leitura dos atendimentos criados
            Vue.lodash.each(this.atendimentos, atendimento => {
                // Identificar tipo de atendimento
                const TipoAtendimento = atendimento.TipoAtendimento === 'prateleira' ? 'Prateleira Infinita' : 'Venda Link'
                whatsAppMessage = whatsAppMessage.concat(`Tipo de Atendimento: ${TipoAtendimento} \n`)

                // Listar Produtos
                Vue.lodash.each(atendimento.Produtos, produto => {
                    whatsAppMessage = whatsAppMessage.concat(`${produto.Quantidade}x ${produto.SKU} - ${produto.Tamanho} \n`)
                })

                // Listar ID do atendimento
                const urlAtendimento = store.getters.url.concat('atendimento/').concat(atendimento.IdAtendimento)
                whatsAppMessage = whatsAppMessage.concat(urlAtendimento).concat('\n \n')
            })

            // Enviar ao WhatsApps
            this.sendWhatsappToReseller(this.reseller, whatsAppMessage)

            // Limpar produtos
            this.clearBag();
        },
        clearBag(){
            // Fechar modal e zerar carrinho
            store.dispatch('clearBag', this.reseller)
                .then(() => {
                    // Limpar formulario
                    this.bloquearNovoAtendimento = false
                    this.form = {
                        nome: {
                            value: '',
                            error: null,
                        },
                        cpf: {
                            value: '',
                            error: null,
                        },
                    }
                    // Atualizar carrinho
                    this.$emit('reloadBag')
                    // Fechar janela
                    this.$bvModal.hide('modal-preatendimento')
                    // Enviar para o GTM
                    this.$gtm.trackEvent({'event': 'click finalizar compra'})
                })
        },
        getMaxEstoqueDisponivel(product) {

            // Identificar o estoque pela loja do produto
            const estoqueDisponivel = Vue.lodash.find(product.estoque, estoque => {
                return estoque.StoreCode === product.CodLoja
            })

            return estoqueDisponivel.Quantity
        },
        updateQuantidade(quantidade, product) {
            // Verificar se a quantidade informada é maior que zero
            if (quantidade > 0) {
                product.quantidade = quantidade
                store.dispatch('updateProductFromBag', product)
                    .then(() => {
                        this.$emit('reloadBag')
                    })
            }
            else {
                // Remover o produto
                store.dispatch('deleteProductFromBag', product)
                    .then(() => {

                        this.$gtm.trackEvent({
                            'event': 'removeFromCart',
                            'ecommerce': {
                                'remove': {
                                    'products': [
                                        {
                                            'name': product.Nome,
                                            'id': product.CdItemMaterial,
                                            'price': product.Preco,
                                            'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                            // 'category': this.$route.params.VitrineResellerId,
                                            'variant': product.size,
                                            'quantity': 1,
                                        },
                                    ],
                                },
                            },
                        })

                        this.$emit('reloadBag')
                    })
            }
        },
        changeSidebarMenuStatus(status) {
            this.$emit('changeSidebarMenuStatus', status)
        },
    },
    computed: {
        isVitrineTodosProdutos() {
            // Identificar primeira vitrine
            const VitrineTodosProdutos = Vue.lodash.first(this.destaques)
            const VitrineID = Vue.lodash.isEmpty(VitrineTodosProdutos) ? null : VitrineTodosProdutos.VitrineID

            // Comparar com a vitrine do revendedor
            return VitrineID == this.reseller.VitrineRevendedorID
        },
        validarNome() {
            if (this.form.nome.value.trim()) {
                return this.form.nome.value.length >= 3
            }

            return null
        },
        validarCPF() {
            if (this.form.cpf.value.trim()) {
                return this.isCPFValid(this.form.cpf.value)
            }
            return null
        },
        total() {
            // Calcular total do carrinho
            return this.lodash.reduce(this.bag, function (memo, product) {
                return memo + (product.Preco * product.quantidade)
            }, 0)
        },
        // Filtros Ordenados
        categoriasOrdenadas() {
            return Vue.lodash.orderBy(this.filtros.Categorias, 'DescCategoria')
        },
        coresOrdenadas() {
            return Vue.lodash.orderBy(this.filtros.Cores, 'Cor')
        },
        tamanhoDeBolsasOrdenadas() {
            return Vue.lodash.orderBy(this.filtros.TamanhosBolsas, 'TamBolsa')
        },
        tamanhoDeSaltosOrdenados() {
            return Vue.lodash.orderBy(this.filtros.TamanhosSaltos, 'TamSalto')
        },
        materiaisOrdenados() {
            return Vue.lodash.orderBy(this.filtros.Material, 'Material')
        },
    },
    watch: {
        '$route.params.CdIteMaterial': function (CdIteMaterial) {
            if (Vue.lodash.isEmpty(CdIteMaterial) === false) {
                this.showHeaderbarSearch = false
            }
        },
    },
}
</script>
