<template>
    <div id="banners" class="row banners" v-if="banners.length > 0">
        <b-carousel :interval="4000">
            <b-carousel-slide
                v-for="(banner, index) in banners"
                v-bind:key="index" :img-src="banner.Url"
            ></b-carousel-slide>
        </b-carousel>
    </div>
</template>
<style lang="scss">
#banners
{
    display: flex;
    justify-content: center;

    .carousel-inner
    {
        img
        {
            width: 414px;
            max-width: 414px;
            max-height: 200px;
        }
    }
}

.is-desktop
{
    #banners
    {
        .carousel-inner
        {
            img
            {
                width: 100%;
                max-width: 1920px;
            }
        }
    }
}
</style>
<script>
import Vue from 'vue'
import {Utils} from '@/components/utils'

export default {
    name: 'banners',
    props: ['reseller'],
    mixins: [Utils],
    data() {
        return {
            banners: [],
        }
    },
    watch:
        {
            reseller() {
                this.banners = Vue.lodash.filter(this.reseller.Banners, banner => {
                    const tipo = this.isDesktop() ? 1 : 2
                    return banner.Tipo === tipo
                })
            },
        },
}
</script>
